import React from 'react'

interface LegalFooterProps {
	children: React.ReactNode
	className: string
	hasLogoOnLegalFooter?: boolean | null | undefined
	isPromomatsClasses?: boolean
}

export const LegalFooter = ({
	children,
	className,
	hasLogoOnLegalFooter = true,
	isPromomatsClasses = false,
}: LegalFooterProps): JSX.Element => {
	return (
		<div
			className={`bg-main flex items-center justify-between overflow-hidden px-4 py-8 text-center  md:text-left ${className}`}
		>
			{(hasLogoOnLegalFooter && (
				<svg
					className="fill-current text-primary"
					height={61.742}
					viewBox="0 0 637.38 316.17"
					width={124.47}
				>
					<title>Elanco Logo</title>
					<g>
						<polygon points="617.07 226.46 620.37 226.46 620.37 235.29 621.86 235.29 621.86 226.46 625.15 226.46 625.15 225.11 617.07 225.11 617.07 226.46" />
						<polygon points="628.83 235.29 628.83 226.09 628.9 226.09 631.7 235.29 632.91 235.29 635.79 226.09 635.85 226.09 635.85 235.29 637.35 235.29 637.35 225.11 634.66 225.11 632.42 232.67 632.35 232.67 629.94 225.11 627.33 225.11 627.33 235.29 628.83 235.29" />
						<path d="M422.66,164.28c-11.55,0-16.36,34.64-16.36,40.42,0,5.14,1.77,7.54,6.25,7.54,11.56,0,16.37-34.81,16.37-40.42S426.84,164.28,422.66,164.28Z" />
						<path d="M0,111.88,36.6,316.17l600.78-106.3V0ZM106.51,229.72H39.46L63.2,117.91h65l-7.06,23.58H86.94l-4,18.77h27.92L106,183H78.12l-4.81,22.79h38.34Zm29.68,2.09c-23.58,0-21.5-12.18-18-28.71l12-56.47c4.16-19.25,4.49-25.34,4.33-30l29-4.81c.32,4-.64,12.51-2.4,20.69L148.07,194c-3.06,14.6-3.38,18.46,2.88,18.46a10.85,10.85,0,0,0,3-.49l.81,17.33C150.79,230.69,143.89,231.81,136.19,231.81Zm71.91,1.13c-3.37-1.77-5.77-6.74-6.25-9.79-3.7,3.85-11.88,9.15-21.5,9.15-16.85,0-23.91-10.6-23.91-28.24s7.06-35.93,17.81-46.68c9.47-9.47,19.58-12.52,34.17-12.52,11.39,0,25.51,1.93,31.6,5-2.4,5.77-4.81,15.71-6.25,22.62l-6.25,29.84c-.65,2.72-.49,8.5,3.2,12.51ZM276,229.72l11.23-52.93c1.44-6.42.64-9.31-4-9.31-4.49,0-8,2.25-11.55,7.71l-11.71,54.53H232l11.07-52.13c2.57-12.51,3.53-19.89,2.25-28.08l24.7-4.65a47.23,47.23,0,0,1,1.45,9c6.41-5.6,16-9,25.18-9,20.69,0,22.79,11.72,20.22,23.75l-13,61.11Zm68.66,2.09c-23.1,0-34.33-14.76-28.39-42.67,5.93-28.08,22.94-44.28,47.32-44.28,11.87,0,18.29,3,25,9.79l-16.37,16.84c-2.4-3.36-6.58-6.09-10.42-6.09-6.42,0-11.23,5.13-14.76,22s-3.21,23.74,4.81,23.74c6.25,0,12-3.05,16.69-7.38l8.34,16.36A49.84,49.84,0,0,1,344.62,231.81Zm63.12,0c-19.57,0-30.64-10.75-30.64-29.84,0-21.33,11.56-57.1,50.7-57.1,17.32,0,30.64,8.5,30.64,29.35C458.44,195.88,446.41,231.81,407.74,231.81Z" />
						<path d="M194,170.06c-5.93,6.89-9.46,23.09-9.46,33.36,0,5.61,1.6,8,5.45,8,3.37,0,9.15-5.77,9.79-8.49l7.7-36.26a5.68,5.68,0,0,0-3.37-1.13A11.77,11.77,0,0,0,194,170.06Z" />
					</g>
				</svg>
			)) ?? (
				<svg
					className="mt-6 shrink-0 md:mt-0"
					height="61.742"
					viewBox="0 0 124.47 61.742"
					width="124.47"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g transform="translate(0 0)">
						<path
							d="M310.1,198.9c-2.256,0-3.2,6.767-3.2,7.895,0,1,.345,1.472,1.222,1.472,2.256,0,3.2-6.8,3.2-7.895S310.918,198.9,310.1,198.9Z"
							fill="#0067b1"
							transform="translate(-227.565 -166.818)"
						/>
						<path
							d="M156.684,200.656c-1.159,1.347-1.848,4.511-1.848,6.516,0,1.1.313,1.566,1.065,1.566a2.984,2.984,0,0,0,1.911-1.66l1.5-7.08a1.1,1.1,0,0,0-.658-.219A2.3,2.3,0,0,0,156.684,200.656Z"
							fill="#0067b1"
							transform="translate(-118.803 -167.447)"
						/>
						<path
							d="M28.315,108.1,35.462,148l117.322-20.757V86.256Zm20.8,23.015H36.02l4.637-21.835H53.344l-1.378,4.6H45.293l-.783,3.666h5.451L49.021,122H43.57l-.94,4.449h7.488Zm5.8.407c-4.605,0-4.2-2.381-3.509-5.608l2.35-11.027a23.5,23.5,0,0,0,.846-5.858l5.67-.94a17.4,17.4,0,0,1-.47,4.042l-2.569,12c-.6,2.851-.658,3.6.564,3.6a2.129,2.129,0,0,0,.6-.094l.157,3.383A11.722,11.722,0,0,1,54.91,131.526Zm14.043.219a2.923,2.923,0,0,1-1.222-1.911,6.179,6.179,0,0,1-4.2,1.786c-3.289,0-4.668-2.068-4.668-5.514a13.438,13.438,0,0,1,3.477-9.116c1.848-1.848,3.822-2.444,6.673-2.444a17.305,17.305,0,0,1,6.172.971,29.249,29.249,0,0,0-1.222,4.417l-1.222,5.827a2.9,2.9,0,0,0,.627,2.444Zm13.251-.627L84.4,120.78c.282-1.253.125-1.817-.783-1.817a2.628,2.628,0,0,0-2.256,1.5l-2.287,10.651H73.621l2.162-10.182a14.686,14.686,0,0,0,.439-5.483l4.825-.908a9.342,9.342,0,0,1,.282,1.754,7.727,7.727,0,0,1,4.918-1.754c4.042,0,4.449,2.287,3.948,4.637l-2.538,11.936Zm13.409.407c-4.511,0-6.705-2.882-5.546-8.333,1.16-5.482,4.48-8.646,9.242-8.646a6.047,6.047,0,0,1,4.887,1.911l-3.2,3.289a2.742,2.742,0,0,0-2.036-1.19c-1.253,0-2.193,1-2.882,4.292s-.627,4.637.94,4.637a4.867,4.867,0,0,0,3.258-1.441l1.629,3.2A9.728,9.728,0,0,1,95.613,131.526Zm12.327,0c-3.822,0-5.984-2.1-5.984-5.827,0-4.167,2.256-11.153,9.9-11.153,3.383,0,5.983,1.66,5.983,5.733C117.839,124.508,115.49,131.526,107.94,131.526Z"
							fill="#0067b1"
							transform="translate(-28.315 -86.256)"
						/>
					</g>
					<path
						d="M451.432,240.877h.643V242.6h.292v-1.724h.643v-.264h-1.579Z"
						fill="#0067b1"
						transform="translate(-330.929 -196.652)"
					/>
					<path
						d="M458.758,242.6v-1.8h.015l.548,1.8h.235l.563-1.8h.013v1.8h.293v-1.988H459.9l-.439,1.476h-.013l-.468-1.476h-.511V242.6Z"
						fill="#0067b1"
						transform="translate(-335.96 -196.652)"
					/>
				</svg>
			)}
			<div className={!isPromomatsClasses ? 'md:w-5/6' : 'md:w-full'}>
				{children}
			</div>
		</div>
	)
}
